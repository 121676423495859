export const CLIENT_FEATURE_FLAGS = Object.freeze({
  VHPMFE: 'vhp-mfe',
  SAFETY_BANNER: 'growth-all-vhp-safety-banner',
  NAV_LOGIN_LINK: 'nav-login-link',
  VHP_RECAPTCHA_ENABLED: 'growth-vhp-mfe-recaptcha-enabled',
  TTP_ASSET_URL: 'ttp-asset-url',
  VHP_FONT_SELECTOR: 'growth-all-vhp-header-font-update',
  VHP_VERTICAL_HOVER: 'growth-all-vhp-vertical-hover-state',
  SC_PROVIDER_ENROLLMENT: 'sc-provider-enrollment',
  VHP_TRIAGE_PROVIDER_VERTICAL: 'growth-vhp-all-triage-provider-vertical',
  APPLY_TO_JOBS_MODAL: 'apply-for-jobs-modal',
  GROWTH_VHP_ALL_LIST_YOUR_BUSINESS_CTA: 'growth-vhp-all-list-your-business-cta',
  VHP_2_0_MILESTONE_1: 'growth-vhp-bm2.0-m1',
  RTX_TRACKING_LAZY_LOAD: 'rtx-lazy-load',
  GROWTH_VHP_ADULT_CARE_TILE: 'growth-vhp-adult-care-tile',
  GROWTH_PROVIDER_HEADER: 'growth-provider-enrollment-provider-landing-page-header',
});

export const SERVER_FEATURE_FLAGS: Readonly<Record<string, string>> = Object.freeze({
  SENTRY_TRACE_TRANSACTION_SAMPLING_RATES: 'sentry-trace-transaction-sampling-rates',
  GTM_ENABLED: 'gtm-enabled',
  GTM_JS_URL: 'gtm-js-url',
  ACCESSIBE_ENABLED: 'accessibe-enabled',
});

export const WINDOW_SENTRY_TRACE_TRANSACTION_SAMPLING_RATES_KEY =
  'SENTRY_TRACE_TRANSACTION_SAMPLING_RATES';

export const TEST_COOKIE_KEY = 'n_tc';
export const CZEN_VISITOR_COOKIE_KEY = 'n_vis';
export const CZEN_SESSION_COOKIE_KEY = 'csc';
export const CARE_DEVICE_ID_COOKIE_KEY = 'care_did';
export const CZEN_JSESSIONID_COOKIE_KEY = 'JSESSIONID';
export const LOCAL_STORAGE_ERROR_TAG = 'LocalStorageError';
export const LOCAL_STORAGE_STATE_KEY = 'vhp-mfe-state';
export const LOCAL_STORAGE_VERIFICATION_KEY = 'LocalStorageVerification';
export const LOCAL_STORAGE_VERIFICATION_VALUE = 'Verified';
export const CLIENT_SIDE_ERROR_TAG = 'ClientSideError';
// This constant considers paddings and margins as calc(100vh - (56px header + 24 + 24 paddings))
export const HEIGHT_MINUS_TOOLBAR_HEIGHT_WITH_PADDING = 'calc(100vh - 104px)';
export const TOOLBAR_HEIGHT_WITH_PADDING = '104px';
export const HOMEPAY_MOBILE_URL =
  '/visitor/homePayCrossSell.do?rx=Care_Asset|Mobile%20VHP%20Banner|MobileVHP||nannytaxesandpayrollhelp|||||&redirectURL=/homepay';
export const HOMEPAY_DESKTOP_URL =
  '/visitor/homePayCrossSell.do?rx=Care_Asset%7CDesktop%20VHP%20Toolbar%7CDesktop%20VHP%7CToolbar%7C%7C%7C%7C%7C%7C&redirectURL=/homepay';
export const SAFETY_CARD_LINK = '/safety';
export const CALCULATOR_PAGE_URL = '/cost-of-childcare';
export const SAFETY_PAGE_URL = '/about/safety/';
export const LIST_YOUR_BUSINESS_URL = '/app/pn-business';

export const SEO_PAGE_ID = 1000;

export type StringKeysObj = { [key: string]: string };

export const PROMO_CODES: StringKeysObj = {
  JOINCARE20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  JOINCARE15:
    'Find your perfect caregiver with 15% off Premium Membership. Discount applied at checkout.',
  LIFEMART30:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
  CAREPERK20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  GOOGLE20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  BING20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  MEGHAN20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  CAROLINE20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  DANA20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  TAMARA20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  KERMILIA20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  ALICE20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  GOPREMIUM20:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  SHYFT20:
    'Welcome Shyft customers! Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  FALL2021:
    'Find your perfect caregiver with 20% off Premium Membership. Discount applied at checkout.',
  LMCARE30:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
  META30:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
  YOUTUBE30:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
  PREMIUM30:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
  TAKE30:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
  UPGRADE30:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
  YOUTUBE2023:
    'Find your perfect caregiver with 30% off Premium Membership. Discount applied at checkout.',
};

export const DEFAULT_VERTICAL = 'none';
export const UNKNOWN_VERTICAL = 'unknown';

export enum VERTICALS {
  ADULT_CARE = 'ADULTCARE',
  CHILD_CARE = 'CHILDCARE',
  DAY_CARE = 'DAYCARE',
  HOUSEKEEPING = 'HOUSEKEEP',
  PET_CARE = 'PETCAREXX',
  SENIOR_CARE = 'SENIRCARE',
  TUTORING = 'TUTORINGX',
}

export const VERTICALS_DISPLAY_NAMES = Object.freeze({
  [VERTICALS.ADULT_CARE]: 'Adult care',
  [VERTICALS.CHILD_CARE]: 'Child care',
  [VERTICALS.DAY_CARE]: 'Daycare',
  [VERTICALS.HOUSEKEEPING]: 'Housekeeping',
  [VERTICALS.PET_CARE]: 'Pet care',
  [VERTICALS.SENIOR_CARE]: 'Senior care',
  [VERTICALS.TUTORING]: 'Tutoring',
});

export const VERTICALS_NAMES = Object.freeze({
  [VERTICALS.ADULT_CARE]: 'Adultcare',
  [VERTICALS.CHILD_CARE]: 'Childcare',
  [VERTICALS.DAY_CARE]: 'Daycare',
  [VERTICALS.HOUSEKEEPING]: 'Housekeeping',
  [VERTICALS.PET_CARE]: 'Petcare',
  [VERTICALS.SENIOR_CARE]: 'Seniorcare',
  [VERTICALS.TUTORING]: 'Tutoring',
  [DEFAULT_VERTICAL]: 'none',
  [UNKNOWN_VERTICAL]: 'Unknown',
});

export const SEEKER_ENROLL = Object.freeze({
  [VERTICALS.CHILD_CARE]: '/app/enrollment/seeker/cc',
  [VERTICALS.SENIOR_CARE]: '/app/enrollment/seeker/sc',
  [VERTICALS.TUTORING]: '/app/enrollment/seeker/tu',
  [VERTICALS.HOUSEKEEPING]: '/app/enrollment/seeker/hk',
  [VERTICALS.PET_CARE]: '/app/enrollment/seeker/pc',
  [VERTICALS.DAY_CARE]: '/app/enrollment/seeker/cc?fromSemPage=VHP&serviceIdForMember=dayCare',
  [VERTICALS.ADULT_CARE]: '/app/enrollment/seeker/sc',
});

export const SITTER_MV_ENROLL: Partial<Record<VERTICALS, string>> = Object.freeze({
  [VERTICALS.CHILD_CARE]: '/app/enrollment/provider/mv/CHILDCARE',
  [VERTICALS.SENIOR_CARE]: '/app/enrollment/provider/mv/SENIRCARE',
  [VERTICALS.TUTORING]: '/app/enrollment/provider/mv/TUTORINGX',
  [VERTICALS.HOUSEKEEPING]: '/app/enrollment/provider/mv/HOUSEKEEP',
  [VERTICALS.PET_CARE]: '/app/enrollment/provider/mv/PETCAREXX',
  [VERTICALS.ADULT_CARE]: '/app/enrollment/provider/mv/SENIRCARE',
});

export const SITTER_SMB_ENROLL = '/business-p1369-q59964639.html';

export const SITTER_ENROLL =
  '/mobile/visitor/captureProviderVerticals.do?skipVertical=true&serviceId=';

export const SEEKER_VERTICAL_TRIAGE_PAGE = '/app/vhp/vertical-triage';
export const SITTER_DEFAULT_VERTICAL_DT_ENROLL = SITTER_MV_ENROLL[VERTICALS.CHILD_CARE];
export const SITTER_DEFAULT_VERTICAL_MW_ENROLL = `${SITTER_ENROLL}${VERTICALS.CHILD_CARE}`;

export const HIW_PAGE_URL = '/hiw';

export const SENIOR_CARE_ENROLLMENT = Object.freeze({
  MV: '/app/enrollment/provider/mv/SENIRCARE',
  MFE: '/app/enrollment/provider/sc',
});

export const SENIOR_CARE_SITTER_TYPE = Object.freeze({
  SMB: 'small-business',
  INDIVIDUAL: 'individual',
});

export const FOOTER_ABOUT_CARE_LINKS = [
  {
    label: 'About us',
    address: '/about/',
  },
  {
    label: 'News coverage',
    address: '/news-coverage',
  },
  {
    label: 'Careers',
    address: '/careers',
  },
  {
    label: 'Terms of use',
    address: '/about/terms-of-use',
    rel: 'nofollow',
  },
  {
    label: 'Privacy policy',
    address: '/about/privacy-policy',
    rel: 'nofollow',
  },
  {
    label: 'Care.org',
    address: 'https://www.care.org/',
  },
];

export const FOOTER_GET_HELP_LINKS = [
  {
    label: 'Safety',
    address: SAFETY_PAGE_URL,
  },
  {
    label: 'Articles & Guides',
    address: '/c/',
  },
  {
    label: 'Help Center/Contact Us',
    address: 'https://help.care.com/families/s/',
  },
];

export const FOOTER_DISCOVER_LINKS = [
  {
    label: 'Homepay™️ - Nanny Tax help',
    address: '/homepay',
  },
  {
    label: 'List your business',
    address: LIST_YOUR_BUSINESS_URL,
  },
  {
    label: 'Care for business',
    address: '/business',
  },
  {
    label: 'Become an affiliate',
    address:
      'https://app.impact.com/campaign-promo-signup/carecom.brand?execution=e1s1#/?viewkey=signUpPreStart',
  },
  {
    label: 'Care.com directory',
    address: '/care-directory',
  },
  {
    label: 'Care cost guides',
    address: '/cost',
  },
];

export const FOOTER_SUBVERTICAL_LINKS = {
  forChildren: [
    {
      label: 'Child care',
      address: '/child-care',
    },
    {
      label: 'Child care jobs',
      address: '/child-care-jobs',
    },
    {
      label: 'Child care centers',
      address: '/child-care-centers',
    },
    {
      label: 'Babysitters',
      address: '/babysitters',
    },
    {
      label: 'Babysitting jobs',
      address: '/babysitting-jobs',
    },
    {
      label: 'Daycare',
      address: '/day-care',
    },
    {
      label: 'Nannies',
      address: '/nannies',
    },
    {
      label: 'Nanny jobs',
      address: '/nanny-jobs',
    },
    {
      label: 'Preschools',
      address: '/preschools',
    },
    {
      label: 'Activities & camps',
      address: '/explore',
    },
    {
      label: 'Tutors',
      address: '/tutors',
    },
    {
      label: 'Tutoring jobs',
      address: '/tutoring-jobs',
    },
    {
      label: 'Math tutors',
      address: '/math-tutors',
    },
  ],
  forAdultsSeniors: [
    {
      label: 'Senior care',
      address: '/senior-care',
    },
    {
      label: 'Senior care jobs',
      address: '/senior-care-jobs',
    },
    {
      label: 'Home care',
      address: '/home-care',
    },
    {
      label: 'Home care jobs',
      address: '/home-care-jobs',
    },
    {
      label: 'Caregivers',
      address: '/caregivers',
    },
    {
      label: 'Caregiver jobs',
      address: '/caregiver-jobs',
    },
    {
      label: 'Personal assistants',
      address: '/personal-assistants',
    },
    {
      label: 'Assisted living',
      address: '/assisted-living',
    },
  ],
  forPets: [
    {
      label: 'Pet care',
      address: '/pet-care',
    },
    {
      label: 'Pet care jobs',
      address: '/pet-care-jobs',
    },
    {
      label: 'Dog walkers',
      address: '/dog-walkers',
    },
    {
      label: 'Dog walking jobs',
      address: '/dog-walking-jobs',
    },
    {
      label: 'Pet sitters',
      address: '/pet-sitters',
    },
    {
      label: 'Pet sitting jobs',
      address: '/pet-sitting-jobs',
    },
    {
      label: 'Dog boarding',
      address: '/dog-boarding',
    },
    {
      label: 'Dog boarding jobs',
      address: '/dog-boarding-jobs',
    },
  ],
  forHome: [
    {
      label: 'Housekeepers',
      address: '/housekeepers',
    },
    {
      label: 'Housekeeping jobs',
      address: '/housekeeping-jobs',
    },
    {
      label: 'House cleaning',
      address: '/house-cleaning',
    },
    {
      label: 'House cleaning jobs',
      address: '/house-cleaning-jobs',
    },
    {
      label: 'Maids',
      address: '/maids',
    },
    {
      label: 'Maid jobs',
      address: '/maid-jobs',
    },
    {
      label: 'Errand runners',
      address: '/errand-runners',
    },
    {
      label: 'Part time jobs',
      address: '/part-time-jobs ',
    },
  ],
};

export const FOOTER_PROGRAM_AND_RESOURCES_LINKS = [
  {
    label: 'Articles & resources',
    address: '/c/',
  },
  {
    label: 'Child care articles & resources',
    address: '/c/spaces/1/children/',
  },
  {
    label: 'Senior care articles & resources',
    address: '/c/spaces/8/seniors/',
  },
  {
    label: 'Pet care articles & resources',
    address: '/c/spaces/5/pets/',
  },
  {
    label: 'House &  home articles & resources',
    address: '/c/spaces/4/house-home/',
  },
  {
    label: 'Complete guide for families & caregivers',
    address: '/c/guides/families-caregivers/',
  },
  {
    label: 'Nanny tax guide',
    address: '/homepay/nanny-tax-guide',
  },
  {
    label: 'Community',
    address: '/community',
  },
  {
    label: 'Find jobs',
    address: '/jobs',
    rel: 'nofollow',
  },
  {
    label: 'Babysitting rates',
    address: '/babysitting-rates',
  },
  {
    label: 'Pet sitting rates',
    address: '/pet-sitting-rates',
  },
  {
    label: 'Housekeeping rates',
    address: '/housekeeping-rates',
  },
  {
    label: 'Caregiver rates',
    address: '/caregiver-rates',
  },
];

export const LOCAL_CAREGIVERS_LINKS = () => {
  return {
    byState: [
      { label: 'Alabama', address: 'https://www.care.com/all-services/al' },
      { label: 'Alaska', address: 'https://www.care.com/all-services/ak' },
      { label: 'Arizona', address: 'https://www.care.com/all-services/az' },
      { label: 'Arkansas', address: 'https://www.care.com/all-services/ar' },
      { label: 'California', address: 'https://www.care.com/all-services/ca' },
      { label: 'Colorado', address: 'https://www.care.com/all-services/co' },
      { label: 'Connecticut', address: 'https://www.care.com/all-services/ct' },
      { label: 'Delaware', address: 'https://www.care.com/all-services/de' },
      { label: 'Florida', address: 'https://www.care.com/all-services/fl' },
      { label: 'Georgia', address: 'https://www.care.com/all-services/ga' },
      { label: 'Hawaii', address: 'https://www.care.com/all-services/hi' },
      { label: 'Idaho', address: 'https://www.care.com/all-services/id' },
      { label: 'Illinois', address: 'https://www.care.com/all-services/il' },
      { label: 'Indiana', address: 'https://www.care.com/all-services/in' },
      { label: 'Iowa', address: 'https://www.care.com/all-services/ia' },
      { label: 'Kansas', address: 'https://www.care.com/all-services/ks' },
      { label: 'Kentucky', address: 'https://www.care.com/all-services/ky' },
      { label: 'Louisiana', address: 'https://www.care.com/all-services/la' },
      { label: 'Maine', address: 'https://www.care.com/all-services/me' },
      { label: 'Maryland', address: 'https://www.care.com/all-services/md' },
      { label: 'Massachusetts', address: 'https://www.care.com/all-services/ma' },
      { label: 'Michigan', address: 'https://www.care.com/all-services/mi' },
      { label: 'Minnesota', address: 'https://www.care.com/all-services/mn' },
      { label: 'Mississippi', address: 'https://www.care.com/all-services/ms' },
      { label: 'Missouri', address: 'https://www.care.com/all-services/mo' },
      { label: 'Montana', address: 'https://www.care.com/all-services/mt' },
      { label: 'Nebraska', address: 'https://www.care.com/all-services/ne' },
      { label: 'Nevada', address: 'https://www.care.com/all-services/nv' },
      { label: 'New Hampshire', address: 'https://www.care.com/all-services/nh' },
      { label: 'New Jersey', address: 'https://www.care.com/all-services/nj' },
      { label: 'New Mexico', address: 'https://www.care.com/all-services/nm' },
      { label: 'New York', address: 'https://www.care.com/all-services/ny' },
      { label: 'North Carolina', address: 'https://www.care.com/all-services/nc' },
      { label: 'North Dakota', address: 'https://www.care.com/all-services/nd' },
      { label: 'Ohio', address: 'https://www.care.com/all-services/oh' },
      { label: 'Oklahoma', address: 'https://www.care.com/all-services/ok' },
      { label: 'Oregon', address: 'https://www.care.com/all-services/or' },
      { label: 'Pennsylvania', address: 'https://www.care.com/all-services/pa' },
      { label: 'Puerto Rico', address: 'https://www.care.com/all-services/pr' },
      { label: 'Rhode Island', address: 'https://www.care.com/all-services/ri' },
      { label: 'South Carolina', address: 'https://www.care.com/all-services/sc' },
      { label: 'South Dakota', address: 'https://www.care.com/all-services/sd' },
      { label: 'Tennessee', address: 'https://www.care.com/all-services/tn' },
      { label: 'Texas', address: 'https://www.care.com/all-services/tx' },
      { label: 'Utah', address: 'https://www.care.com/all-services/ut' },
      { label: 'Vermont', address: 'https://www.care.com/all-services/vt' },
      { label: 'Virginia', address: 'https://www.care.com/all-services/va' },
      { label: 'Washington', address: 'https://www.care.com/all-services/wa' },
      { label: 'Washington D.C.', address: 'https://www.care.com/all-services/dc' },
      { label: 'West Virginia', address: 'https://www.care.com/all-services/wv' },
      { label: 'Wisconsin', address: 'https://www.care.com/all-services/wi' },
      { label: 'Wyoming', address: 'https://www.care.com/all-services/wy' },
    ],
    babysitters: [
      { label: 'Atlanta Babysitters', address: 'https://www.care.com/babysitters/atlanta-ga' },
      { label: 'Austin Babysitters', address: 'https://www.care.com/babysitters/austin-tx' },
      { label: 'Boston Babysitters', address: 'https://www.care.com/babysitters/boston-ma' },
      { label: 'Charlotte Babysitters', address: 'https://www.care.com/babysitters/charlotte-nc' },
      { label: 'Chicago Babysitters', address: 'https://www.care.com/babysitters/chicago-il' },
      { label: 'Dallas Babysitters', address: 'https://www.care.com/babysitters/dallas-tx' },
      { label: 'Denver Babysitters', address: 'https://www.care.com/babysitters/denver-co' },
      { label: 'Houston Babysitters', address: 'https://www.care.com/babysitters/houston-tx' },
      {
        label: 'Los Angeles Babysitters',
        address: 'https://www.care.com/babysitters/los-angeles-ca',
      },
      { label: 'Miami Babysitters', address: 'https://www.care.com/babysitters/miami-fl' },
      { label: 'New York Babysitters', address: 'https://www.care.com/babysitters/new-york-ny' },
      { label: 'Orlando Babysitters', address: 'https://www.care.com/babysitters/orlando-fl' },
      {
        label: 'Philadelphia Babysitters',
        address: 'https://www.care.com/babysitters/philadelphia-pa',
      },
      { label: 'Phoenix Babysitters', address: 'https://www.care.com/babysitters/phoenix-az' },
      {
        label: 'San Antonio Babysitters',
        address: 'https://www.care.com/babysitters/san-antonio-tx',
      },
      { label: 'San Diego Babysitters', address: 'https://www.care.com/babysitters/san-diego-ca' },
      {
        label: 'San Francisco Babysitters',
        address: 'https://www.care.com/babysitters/san-francisco-ca',
      },
      { label: 'Seattle Babysitters', address: 'https://www.care.com/babysitters/seattle-wa' },
      {
        label: 'Washington, DC Babysitters',
        address: 'https://www.care.com/babysitters/washington-dc',
      },
      {
        label: 'View Babysitters by State',
        address: 'https://www.care.com/babysitters/all-states',
      },
    ],
    nannies: [
      { label: 'Atlanta Nannies', address: 'https://www.care.com/nannies/atlanta-ga' },
      { label: 'Austin Nannies', address: 'https://www.care.com/nannies/austin-tx' },
      { label: 'Boston Nannies', address: 'https://www.care.com/nannies/boston-ma' },
      { label: 'Charlotte Nannies', address: 'https://www.care.com/nannies/charlotte-nc' },
      { label: 'Chicago Nannies', address: 'https://www.care.com/nannies/chicago-il' },
      { label: 'Dallas Nannies', address: 'https://www.care.com/nannies/dallas-tx' },
      { label: 'Denver Nannies', address: 'https://www.care.com/nannies/denver-co' },
      { label: 'Houston Nannies', address: 'https://www.care.com/nannies/houston-tx' },
      { label: 'Los Angeles Nannies', address: 'https://www.care.com/nannies/los-angeles-ca' },
      { label: 'Miami Nannies', address: 'https://www.care.com/nannies/miami-fl' },
      { label: 'New York Nannies', address: 'https://www.care.com/nannies/new-york-ny' },
      { label: 'Orlando Nannies', address: 'https://www.care.com/nannies/orlando-fl' },
      { label: 'Philadelphia Nannies', address: 'https://www.care.com/nannies/philadelphia-pa' },
      { label: 'Phoenix Nannies', address: 'https://www.care.com/nannies/phoenix-az' },
      { label: 'San Antonio Nannies', address: 'https://www.care.com/nannies/san-antonio-tx' },
      { label: 'San Diego Nannies', address: 'https://www.care.com/nannies/san-diego-ca' },
      { label: 'San Francisco Nannies', address: 'https://www.care.com/nannies/san-francisco-ca' },
      { label: 'Seattle Nannies', address: 'https://www.care.com/nannies/seattle-wa' },
      { label: 'Washington, DC Nannies', address: 'https://www.care.com/nannies/washington-dc' },
      { label: 'View Nannies by State', address: 'https://www.care.com/nannies/all-states' },
    ],
    daycare: [
      { label: 'Atlanta Daycares', address: 'https://www.care.com/day-care/atlanta-ga' },
      { label: 'Austin Daycares', address: 'https://www.care.com/day-care/austin-tx' },
      { label: 'Boston Daycares', address: 'https://www.care.com/day-care/boston-ma' },
      { label: 'Charlotte Daycares', address: 'https://www.care.com/day-care/charlotte-nc' },
      { label: 'Chicago Daycares', address: 'https://www.care.com/day-care/chicago-il' },
      { label: 'Dallas Daycares', address: 'https://www.care.com/day-care/dallas-tx' },
      { label: 'Denver Daycares', address: 'https://www.care.com/day-care/denver-co' },
      { label: 'Houston Daycares', address: 'https://www.care.com/day-care/houston-tx' },
      { label: 'Los Angeles Daycares', address: 'https://www.care.com/day-care/los-angeles-ca' },
      { label: 'Miami Daycares', address: 'https://www.care.com/day-care/miami-fl' },
      { label: 'New York Daycares', address: 'https://www.care.com/day-care/new-york-ny' },
      { label: 'Orlando Daycares', address: 'https://www.care.com/day-care/orlando-fl' },
      { label: 'Philadelphia Daycares', address: 'https://www.care.com/day-care/philadelphia-pa' },
      { label: 'Phoenix Daycares', address: 'https://www.care.com/day-care/phoenix-az' },
      { label: 'San Antonio Daycares', address: 'https://www.care.com/day-care/san-antonio-tx' },
      { label: 'San Diego Daycares', address: 'https://www.care.com/day-care/san-diego-ca' },
      {
        label: 'San Francisco Daycares',
        address: 'https://www.care.com/day-care/san-francisco-ca',
      },
      { label: 'Seattle Daycares', address: 'https://www.care.com/day-care/seattle-wa' },
      { label: 'Washington, DC Daycares', address: 'https://www.care.com/day-care/washington-dc' },
      { label: 'View Daycares by State', address: 'https://www.care.com/day-care/all-states' },
    ],
    petSitters: [
      { label: 'Atlanta Pet Sitters', address: 'https://www.care.com/pet-sitters/atlanta-ga' },
      { label: 'Austin Pet Sitters', address: 'https://www.care.com/pet-sitters/austin-tx' },
      { label: 'Boston Pet Sitters', address: 'https://www.care.com/pet-sitters/boston-ma' },
      { label: 'Charlotte Pet Sitters', address: 'https://www.care.com/pet-sitters/charlotte-nc' },
      { label: 'Chicago Pet Sitters', address: 'https://www.care.com/pet-sitters/chicago-il' },
      { label: 'Dallas Pet Sitters', address: 'https://www.care.com/pet-sitters/dallas-tx' },
      { label: 'Denver Pet Sitters', address: 'https://www.care.com/pet-sitters/denver-co' },
      { label: 'Houston Pet Sitters', address: 'https://www.care.com/pet-sitters/houston-tx' },
      {
        label: 'Los Angeles Pet Sitters',
        address: 'https://www.care.com/pet-sitters/los-angeles-ca',
      },
      { label: 'Miami Pet Sitters', address: 'https://www.care.com/pet-sitters/miami-fl' },
      { label: 'New York Pet Sitters', address: 'https://www.care.com/pet-sitters/new-york-ny' },
      { label: 'Orlando Pet Sitters', address: 'https://www.care.com/pet-sitters/orlando-fl' },
      {
        label: 'Philadelphia Pet Sitters',
        address: 'https://www.care.com/pet-sitters/philadelphia-pa',
      },
      { label: 'Phoenix Pet Sitters', address: 'https://www.care.com/pet-sitters/phoenix-az' },
      {
        label: 'San Antonio Pet Sitters',
        address: 'https://www.care.com/pet-sitters/san-antonio-tx',
      },
      { label: 'San Diego Pet Sitters', address: 'https://www.care.com/pet-sitters/san-diego-ca' },
      {
        label: 'San Francisco Pet Sitters',
        address: 'https://www.care.com/pet-sitters/san-francisco-ca',
      },
      { label: 'Seattle Pet Sitters', address: 'https://www.care.com/pet-sitters/seattle-wa' },
      {
        label: 'Washington, DC Pet Sitters',
        address: 'https://www.care.com/pet-sitters/washington-dc',
      },
      {
        label: 'View Pet Sitters by State',
        address: 'https://www.care.com/pet-sitters/all-states',
      },
    ],
    seniorCare: [
      { label: 'Atlanta Senior Care', address: 'https://www.care.com/senior-care/atlanta-ga' },
      { label: 'Austin Senior Care', address: 'https://www.care.com/senior-care/austin-tx' },
      { label: 'Boston Senior Care', address: 'https://www.care.com/senior-care/boston-ma' },
      { label: 'Charlotte Senior Care', address: 'https://www.care.com/senior-care/charlotte-nc' },
      { label: 'Chicago Senior Care', address: 'https://www.care.com/senior-care/chicago-il' },
      { label: 'Dallas Senior Care', address: 'https://www.care.com/senior-care/dallas-tx' },
      { label: 'Denver Senior Care', address: 'https://www.care.com/senior-care/denver-co' },
      { label: 'Houston Senior Care', address: 'https://www.care.com/senior-care/houston-tx' },
      {
        label: 'Los Angeles Senior Care',
        address: 'https://www.care.com/senior-care/los-angeles-ca',
      },
      { label: 'Miami Senior Care', address: 'https://www.care.com/senior-care/miami-fl' },
      { label: 'New York Senior Care', address: 'https://www.care.com/senior-care/new-york-ny' },
      { label: 'Orlando Senior Care', address: 'https://www.care.com/senior-care/orlando-fl' },
      {
        label: 'Philadelphia Senior Care',
        address: 'https://www.care.com/senior-care/philadelphia-pa',
      },
      { label: 'Phoenix Senior Care', address: 'https://www.care.com/senior-care/phoenix-az' },
      {
        label: 'San Antonio Senior Care',
        address: 'https://www.care.com/senior-care/san-antonio-tx',
      },
      { label: 'San Diego Senior Care', address: 'https://www.care.com/senior-care/san-diego-ca' },
      {
        label: 'San Francisco Senior Care',
        address: 'https://www.care.com/senior-care/san-francisco-ca',
      },
      { label: 'Seattle Senior Care', address: 'https://www.care.com/senior-care/seattle-wa' },
      {
        label: 'Washington, DC Senior Care',
        address: 'https://www.care.com/senior-care/washington-dc',
      },
      {
        label: 'View Senior Care by State',
        address: 'https://www.care.com/senior-care/all-states',
      },
    ],
    housekeepers: [
      { label: 'Atlanta Housekeepers', address: 'https://www.care.com/housekeepers/atlanta-ga' },
      { label: 'Austin Housekeepers', address: 'https://www.care.com/housekeepers/austin-tx' },
      { label: 'Boston Housekeepers', address: 'https://www.care.com/housekeepers/boston-ma' },
      {
        label: 'Charlotte Housekeepers',
        address: 'https://www.care.com/housekeepers/charlotte-nc',
      },
      { label: 'Chicago Housekeepers', address: 'https://www.care.com/housekeepers/chicago-il' },
      { label: 'Dallas Housekeepers', address: 'https://www.care.com/housekeepers/dallas-tx' },
      { label: 'Denver Housekeepers', address: 'https://www.care.com/housekeepers/denver-co' },
      { label: 'Houston Housekeepers', address: 'https://www.care.com/housekeepers/houston-tx' },
      {
        label: 'Los Angeles Housekeepers',
        address: 'https://www.care.com/housekeepers/los-angeles-ca',
      },
      { label: 'Miami Housekeepers', address: 'https://www.care.com/housekeepers/miami-fl' },
      { label: 'New York Housekeepers', address: 'https://www.care.com/housekeepers/new-york-ny' },
      { label: 'Orlando Housekeepers', address: 'https://www.care.com/housekeepers/orlando-fl' },
      {
        label: 'Philadelphia Housekeepers',
        address: 'https://www.care.com/housekeepers/philadelphia-pa',
      },
      { label: 'Phoenix Housekeepers', address: 'https://www.care.com/housekeepers/phoenix-az' },
      {
        label: 'San Antonio Housekeepers',
        address: 'https://www.care.com/housekeepers/san-antonio-tx',
      },
      {
        label: 'San Diego Housekeepers',
        address: 'https://www.care.com/housekeepers/san-diego-ca',
      },
      {
        label: 'San Francisco Housekeepers',
        address: 'https://www.care.com/housekeepers/san-francisco-ca',
      },
      { label: 'Seattle Housekeepers', address: 'https://www.care.com/housekeepers/seattle-wa' },
      {
        label: 'Washington, DC Housekeepers',
        address: 'https://www.care.com/housekeepers/washington-dc',
      },
      {
        label: 'View Housekeepers by State',
        address: 'https://www.care.com/housekeepers/all-states',
      },
    ],
    tutors: [
      { label: 'Atlanta Tutors', address: 'https://www.care.com/tutors/atlanta-ga' },
      { label: 'Austin Tutors', address: 'https://www.care.com/tutors/austin-tx' },
      { label: 'Boston Tutors', address: 'https://www.care.com/tutors/boston-ma' },
      { label: 'Charlotte Tutors', address: 'https://www.care.com/tutors/charlotte-nc' },
      { label: 'Chicago Tutors', address: 'https://www.care.com/tutors/chicago-il' },
      { label: 'Dallas Tutors', address: 'https://www.care.com/tutors/dallas-tx' },
      { label: 'Denver Tutors', address: 'https://www.care.com/tutors/denver-co' },
      { label: 'Houston Tutors', address: 'https://www.care.com/tutors/houston-tx' },
      { label: 'Los Angeles Tutors', address: 'https://www.care.com/tutors/los-angeles-ca' },
      { label: 'Miami Tutors', address: 'https://www.care.com/tutors/miami-fl' },
      { label: 'New York Tutors', address: 'https://www.care.com/tutors/new-york-ny' },
      { label: 'Orlando Tutors', address: 'https://www.care.com/tutors/orlando-fl' },
      { label: 'Philadelphia Tutors', address: 'https://www.care.com/tutors/philadelphia-pa' },
      { label: 'Phoenix Tutors', address: 'https://www.care.com/tutors/phoenix-az' },
      { label: 'San Antonio Tutors', address: 'https://www.care.com/tutors/san-antonio-tx' },
      { label: 'San Diego Tutors', address: 'https://www.care.com/tutors/san-diego-ca' },
      { label: 'San Francisco Tutors', address: 'https://www.care.com/tutors/san-francisco-ca' },
      { label: 'Seattle Tutors', address: 'https://www.care.com/tutors/seattle-wa' },
      { label: 'Washington, DC Tutors', address: 'https://www.care.com/tutors/washington-dc' },
      { label: 'View Tutors by State', address: 'https://www.care.com/tutors/all-states' },
    ],
  };
};

export const INTERNATIONAL_LINKS = [
  {
    label: 'United States',
    address: 'https://www.care.com',
  },
  {
    label: 'Australia',
    address: 'https://www.care.com/en-au/',
  },
  {
    label: 'Austria',
    address: 'https://www.betreut.at/',
  },
  {
    label: 'Belgium (Français)',
    address: 'https://www.care.com/fr-be/',
  },
  {
    label: 'Belgium (Nederlands)',
    address: 'https://www.care.com/nl-be/',
  },
  {
    label: 'Canada (English)',
    address: 'https://www.care.com/en-ca/',
  },
  {
    label: 'Canada (Français)',
    address: 'https://www.care.com/fr-ca/',
  },
  {
    label: 'Denmark',
    address: 'https://www.care.com/da-dk/',
  },
  {
    label: 'Finland',
    address: 'https://www.care.com/fi-fi/',
  },
  {
    label: 'France',
    address: 'https://www.care.com/fr-fr/',
  },
  {
    label: 'Germany',
    address: 'https://www.betreut.de/',
  },
  {
    label: 'Ireland',
    address: 'https://www.care.com/en-ie/',
  },
  {
    label: 'New Zealand',
    address: 'https://www.care.com/en-nz/',
  },
  {
    label: 'Norway',
    address: 'https://www.care.com/no-no',
  },
  {
    label: 'Spain',
    address: 'https://www.care.com/es-es/',
  },
  {
    label: 'Sweden',
    address: 'https://www.care.com/sv-se/',
  },
  {
    label: 'Switzerland',
    address: 'https://www.care.com/de-ch/',
  },
  {
    label: 'The Netherlands',
    address: 'https://www.care.com/nl-nl/',
  },
  {
    label: 'United Kingdom',
    address: 'https://www.care.com/en-gb',
  },
];

export const SOCIAL_LINKS: StringKeysObj = {
  facebook: 'https://www.facebook.com/caredotcom',
  twitter: 'https://twitter.com/caredotcom',
  instagram: 'https://instagram.com/caredotcom',
  appStore: '/careiTunesDownload.do',
  googlePlay: '/carePlayStoreDownload.do',
  careWork: '/business',
  listBusiness: LIST_YOUR_BUSINESS_URL,
  youtube: 'https://www.youtube.com/user/caredotcom',
};

export const FOOTER_DISCLAIMER_LINKS = [
  {
    label: 'Terms of Use',
    address: '/about/terms-of-use',
    rel: 'nofollow',
  },
  {
    label: 'Privacy Policy',
    address: '/about/privacy-policy',
    rel: 'nofollow',
  },
  {
    label: 'California Privacy Notice',
    address: '/about/privacy-policy/#privacy-info-california',
    rel: 'nofollow',
  },
  {
    label: 'Do Not Sell or Share My Personal Information',
    address: '#',
  },
];

type GTMSlots = { [key: string]: string };
export const GTM_SLOTS: GTMSlots = {
  VHP: '/us-subscription/segment/nonmember/viewed-vhp/',
};

export const TOP_NAV_LINKS = Object.freeze({
  auth0Login: '/login',
  becomeCaregiver: '/enroll-care-seeker-p1042-qxreasonToEnroll|sitter.html',
  becomeCaregiverVariant2: '/enroll-care-seeker-p1042-q111082615.html',
  joinNowVhpMfe: '/get-started',
  logIn: '/vis/auth/login',
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum MODAL_CTA_TEXTS {
  PROVIDER_TEXT = 'Find a job',
  SEEKER_TUTOR = 'Find a tutor',
  SEEKER_TEXT = 'Find care',
}

export type VerticalSelection =
  | typeof VERTICALS.ADULT_CARE
  | typeof VERTICALS.CHILD_CARE
  | typeof VERTICALS.DAY_CARE
  | typeof VERTICALS.HOUSEKEEPING
  | typeof VERTICALS.PET_CARE
  | typeof VERTICALS.SENIOR_CARE
  | typeof VERTICALS.TUTORING
  | typeof DEFAULT_VERTICAL
  | typeof UNKNOWN_VERTICAL;

export const LAYOUTS = Object.freeze({
  default: 'default',
  valueDrivers: 'valueDrivers',
  rebranding: 'rebranding',
});

export const LAYOUT_VARIANTS = Object.freeze({
  triageTabs: 'triageTabs',
  triageModal: 'triageModal',
  rebranding: 'rebranding',
});

export enum MemberType {
  SEEKER = 'seeker',
  SITTER = 'sitter',
}

export const HOW_IT_WORKS_INSTRUCTIONS = [
  {
    stepNumber: 1,
    headline: 'Tell us what you need',
    subHeadline: 'Share your care needs and start reviewing profiles.',
  },
  {
    stepNumber: 2,
    headline: 'Choose your caregiver',
    subHeadline: "Start a conversation and hire the caregiver that's right for you.",
  },
  {
    stepNumber: 3,
    headline: 'Get 24/7 support',
    subHeadline: 'Chat with a live agent in an average of 1 minute or less.',
  },
] as const;

export const HEADLINE_TEXT_CONFIGURATIONS: {
  [key: number]: {
    [key: string]: {
      [key: string]: string;
    };
  };
} = Object.freeze({
  2: {
    mobile: {
      firstLine: 'Connecting families',
      secondLine: 'with quality,',
      thirdLine: 'local caregivers',
    },
    tabletOrDesktop: {
      firstLine: 'Connecting families with',
      secondLine: 'quality, local caregivers',
      thirdLine: '',
    },
  },
  3: {
    mobile: {
      firstLine: 'Find trusted',
      secondLine: 'caregivers in your',
      thirdLine: 'neighborhood',
    },
    tabletOrDesktop: {
      firstLine: 'Find trusted caregivers',
      secondLine: 'in your neighborhood',
      thirdLine: '',
    },
  },
  4: {
    mobile: {
      firstLine: 'Join 29 million',
      secondLine: "families who've",
      thirdLine: 'turned to Care.com',
    },
    tabletOrDesktop: {
      firstLine: 'Join 29 million families',
      secondLine: "who've turned to Care.com",
      thirdLine: '',
    },
  },
});
